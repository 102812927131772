import {Box, Grid, Typography} from "@mui/material";
import img from '../assets/images/Alumni/presidentimg.jpg';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import {theme} from "../stylesheet";
function PresidentMessage(){
    return(
        <Box
        sx={{
            display:'flex',
            justifyContent:'center',

            flexDirection:'column',
            alignItems:'center'
        }}>
            <Box sx={{
                width:'85%'
            }}>
                <Typography align={'left'} width={'100%'} variant={'h3'} fontWeight={'600'}
                sx={{color:'#091D3D'}}>President’s Message
                </Typography>


            </Box>

            <Box sx={{
                width:'100%',
                marginTop:'50px'
            }}>
                <Grid container spacing={0}>
                    <Grid item md={4}>
                        <img src={img} style={{
                            height:'100%',
                            width:'100%'
                        }}/>
                    </Grid>

                    <Grid item md={8} sx={{
                        background:'#ECECEC',
                        display:'flex',
                        //justifyContent:'center',
                        alignItems:'center',
                        flexDirection:'column'
                        ,padding:'50px'

                    }}>

                        <Typography align={'center'} width={'100%'}  fontWeight={'700'} sx={{color:' #343741',fontSize:'35px'}}>
                        Samith Wijenayake

                        </Typography>
                        <Typography align={'center'} width={'100%'} variant={'h6'} fontWeight={'100'} sx={{color:' #343741',p:1,opacity:'0.8'}}>
                        {/* (Working Committee President) */}
                        </Typography>
                        <Box sx={{
                            color:theme.palette.customColors.brandColors.tertiary,
                            background:theme.palette.primary.main,
                            borderRadius:'50%',
                            padding:'10px',
                            width:'30px',
                            height:'30px',
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center',
                            margin:'10px'
                        }}>
                            <FormatQuoteIcon fontWeight={800}/>
                        </Box>

                        <Typography align={'center'} width={'80%'} variant={'h6'} fontWeight={'100'}
                            sx={{
                                marginTop: '20px', color: '#343741',
                                fontSize: '18px'
                            }}>The Alumni Association of Uva Wellassa University strives to strengthen bonds among graduates, support professional growth, and encourage lifelong learning. By promoting collaboration and sharing valuable experiences, we aim to create a vibrant community that celebrates the accomplishments of our alumni and contributes to the continued success of our university.
                            We invite all Uva Wellassa University alumni to be part of this inspiring journey and make a lasting impact together!

                        </Typography>


                    </Grid>

                </Grid>
            </Box>

        </Box>
    )
}
export default PresidentMessage